import { Record, Union } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Types.js";
import { record_type, string_type, array_type, union_type } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Reflection.js";
import { PartnerId$reflection, AdiId$reflection } from "./Domain.fs.js";
import { contains } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Array.js";
import { safeHash, equals } from "../partnerportal/src/fable_modules/fable-library.3.7.17/Util.js";

export const Test_Development = true;

export class Auth0UserPrivileges extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Admin", "Adi", "NotSet"];
    }
}

export function Auth0UserPrivileges$reflection() {
    return union_type("Auth0Constants.Auth0UserPrivileges", [], Auth0UserPrivileges, () => [[], [], []]);
}

export class Auth0UserClaims extends Record {
    constructor(Privilege, AdiId, Username) {
        super();
        this.Privilege = Privilege;
        this.AdiId = AdiId;
        this.Username = Username;
    }
}

export function Auth0UserClaims$reflection() {
    return record_type("Auth0Constants.Auth0UserClaims", [], Auth0UserClaims, () => [["Privilege", array_type(Auth0UserPrivileges$reflection())], ["AdiId", AdiId$reflection()], ["Username", string_type]]);
}

export function Auth0UserClaims__HasPrivilege_6B2F319B(this$, privilege) {
    return contains(privilege, this$.Privilege, {
        Equals: equals,
        GetHashCode: safeHash,
    });
}

export class Auth0PartnerUserPrivileges extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Partner", "Syndic", "NotSet"];
    }
}

export function Auth0PartnerUserPrivileges$reflection() {
    return union_type("Auth0Constants.Auth0PartnerUserPrivileges", [], Auth0PartnerUserPrivileges, () => [[], [], []]);
}

export class Auth0PartnerUserClaims extends Record {
    constructor(Privilege, PartnerId, Username) {
        super();
        this.Privilege = Privilege;
        this.PartnerId = PartnerId;
        this.Username = Username;
    }
}

export function Auth0PartnerUserClaims$reflection() {
    return record_type("Auth0Constants.Auth0PartnerUserClaims", [], Auth0PartnerUserClaims, () => [["Privilege", array_type(Auth0PartnerUserPrivileges$reflection())], ["PartnerId", PartnerId$reflection()], ["Username", string_type]]);
}

export function Auth0PartnerUserClaims__HasPrivilege_567ADD95(this$, privilege) {
    return contains(privilege, this$.Privilege, {
        Equals: equals,
        GetHashCode: safeHash,
    });
}

